  
* {
  box-sizing: border-box;
  
}

.pb-container {
  background-color: #f1f1f1;
}
  
.react-datepicker__input-container input {
  position: relative;
  width: 100%;
  padding: 0.1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  /* margin-top: 2px; */
  resize: vertical;
}

.react-datepicker {
  display: flex;
  position: relative;
}


.pickerError .react-datepicker__input-container input {
  border: 1px solid red;
}

.col-1-7 { 
  width: 14.25%;
  border: 1px solid #D3D3D3;
  height: 20%;
}

.font-size-0-8 {
  font-size: 0.8rem;
 }

/* View form CSS */
.formRowBorder{
  border-bottom: 1px solid #D3D3D3;
}

.no-border {
  border: 0;
  box-shadow: none; 
}

.navbar-text {
 font-size: 1.0rem;
}

.uuid-text {
  font-size: 0.8rem;
 }
 
.form-text {
  font-size: 0.8vw;
  /* text-align:right; */
}

/* pre {
  font-size: 0.9vw;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif; */
   /* line-height: 1.15; */
   /* white-space:pre-line;  */
   /* white-space: pre-wrap;  */
   /* font-size: 1vw; */
   /* text-align: left; */
  /*  -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent; 
  */
/* } */

.logo-image img{
  width: 46px;
  height: 46px;
  border-radius: 50%;
  overflow: hidden;
  margin-top: -6px;
  pointer-events:none;
  cursor: not-allowed;
  }

/* .main-navbar-color{
  background-color: rgb(60, 60, 60);
} */

/* .bootstrap-select.btn-group .dropdown-menu li a:hover {
  color: whitesmoke !important;
  background: #bf5279 !important;
} */

/* Pagination  */
.paginator {
  font-family: sans-serif;
  text-align: center;
}
.pagination {
  display: flex;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;
}

.pagination a {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #6c7ac9;
  color: #6c7ac9;
}

.pagination__link {
  font-weight: bold;
}

.pagination__link--active a {
  color: #fff;
  background: #6c7ac9;
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}

  